import { BlueMoonDTO, BlueMoonDO } from './';

// Utils

import { createKeysMappers } from '../base/utils/createKeysMappers';

// ----------------

export const blueMoonMapper = {
  toDO(data: BlueMoonDTO.$): BlueMoonDO.$ {
    return {
      ranSharingFeasibleOnTime: data.ran_sharing_feasible_on_time,
      statusForRanSharing: data.status_for_ran_sharing,
      foreseenRelocName: data.foreseen_reloc_name,
      swapRelocComments: data.swap_reloc_comments,
      deliveryForecast: data.delivery_forecast_general,
      obeLegacyConfig: data.obe_legacy_config,
      obeLegacyLayer: data.obe_legacy_layer,
      statusUpdate: data.status_update,
      forecastInfo: data.forecast_info,
      fctE2eIsDate: data.fct_e2e_is_date,
      alternative: data.alternative,
      isArchived: data.is_archived,
      capacityY1: data.capacity_y1,
      remainWeek: data.remain_week,
      weekNumber: data.week_number,
      fctE2eWeek: data.fct_e2e_week,
      readiness: data.readiness,
      targetTx: data.target_tx,
      created: data.created_at,
      updated: data.updated_at,
      fctE2e: data.fct_e2e,
      dDay: data.d_day,
      pri: data.pri,
      id: data.id,
      tx: data.transmission,
      swapActualDate: data.swap_actual_date,
      physicalAcceptanceDate: data.physical_acceptance_date,
      nocFlmTransferDate: data.noc_flm_transfer_date,
      consolidationDate: data.consolidation_date,
      swapForecastDate: data.swap_forecast_date,
    };
  },

  // --------

  toDTO(data: Partial<BlueMoonDO.$>): Partial<BlueMoonDTO.$> {
    return {
      ran_sharing_feasible_on_time: data.ranSharingFeasibleOnTime,
      status_for_ran_sharing: data.statusForRanSharing,
      foreseen_reloc_name: data.foreseenRelocName,
      swap_reloc_comments: data.swapRelocComments,
      obe_legacy_config: data.obeLegacyConfig,
      obe_legacy_layer: data.obeLegacyLayer,
      fct_e2e_is_date: data.fctE2eIsDate,
      status_update: data.statusUpdate,
      forecast_info: data.forecastInfo,
      transmission: data.tx,
      fct_e2e_week: data.fctE2eWeek,
      alternative: data.alternative,
      is_archived: data.isArchived,
      capacity_y1: data.capacityY1,
      remain_week: data.remainWeek,
      week_number: data.weekNumber,
      target_tx: data.targetTx,
      readiness: data.readiness,
      fct_e2e: data.fctE2e,
      d_day: data.dDay,
      pri: data.pri,
      swap_actual_date: data.swapActualDate,
      physical_acceptance_date: data.physicalAcceptanceDate,
      noc_flm_transfer_date: data.nocFlmTransferDate,
      consolidation_date: data.consolidationDate,
      swap_forecast_date: data.swapForecastDate,
    };
  },

  // --------

  ...createKeysMappers<BlueMoonDTO.$, BlueMoonDO.$>([
    ['swap_forecast_date', 'swapActualDate'],
    ['swap_actual_date', 'swapActualDate'],
    ['consolidation_date', 'consolidationDate'],
    ['physical_acceptance_date', 'physicalAcceptanceDate'],
    ['noc_flm_transfer_date', 'nocFlmTransferDate'],
    ['ran_sharing_feasible_on_time', 'ranSharingFeasibleOnTime'],
    ['delivery_forecast_general', 'deliveryForecast'],
    ['status_for_ran_sharing', 'statusForRanSharing'],
    ['foreseen_reloc_name', 'foreseenRelocName'],
    ['swap_reloc_comments', 'swapRelocComments'],
    ['obe_legacy_config', 'obeLegacyConfig'],
    ['obe_legacy_layer', 'obeLegacyLayer'],
    ['forecast_info', 'forecastInfo'],
    ['status_update', 'statusUpdate'],
    ['fct_e2e_week', 'fctE2eWeek'],
    ['alternative', 'alternative'],
    ['capacity_y1', 'capacityY1'],
    ['remain_week', 'remainWeek'],
    ['week_number', 'weekNumber'],
    ['readiness', 'readiness'],
    ['target_tx', 'targetTx'],
    ['fct_e2e', 'fctE2e'],
    ['d_day', 'dDay'],
    ['pri', 'pri'],
  ]),
};
